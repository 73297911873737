<template>
  <div class="forgot-password">
    <div class="forgot-password-section row">
      <h1 class="font-size-xxl col-12">{{ $t("forgot_password.recover_password") }}</h1>
      <h4 class="font-size-md col-12">{{ $t("forgot_password.enter_recovery_email") }}</h4>

      <Form v-on:submit="email_recovery_link">
        <div class="fp-email col-12">
          <InputComponent
              :label="$t('forgot_password.your_email')"
              rules="required|email"
              type-input="email"
              placeholder=""
              v-model="email"
              name="email"
          />
          <div v-if="message" class="alert alert-success mt-3">{{ message }}</div>
        </div>
        <div class="btn-recovery col-12">
          <button type="submit" class="submit-btn btn btn-primary" :disabled="waiting">{{
              $t("forgot_password.email_recovery_link")
            }}
          </button>
        </div>

      </Form>
      <a href="/login" class="font-size-md col-12">{{ $t("forgot_password.back_to_login") }}</a>
    </div>
  </div>
</template>

<script>
import {Form} from "vee-validate";
import InputComponent from "../../components/InputComponent";
import {mapActions} from "vuex";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  name: "ForgotPassword",
  components: {
    InputComponent,
    Form,
  },
  setup() {
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("forgot_password.page_title"),
    })
  },
  data: () => {
    return {
      email: "",
      message: null,
      waiting: false,
    };
  },
  watch: {
    ...mapActions(["setErrors"]),
  },
  methods: {
    email_recovery_link() {
      this.message = null;
      this.waiting = true;
      this.send_email(this.email).then(response => {
        this.message = response.message;
      }, errors => {
        this.message = errors[0].message;
      }).finally(() => {
        this.waiting = false;
      });
    },
    async send_email(email) {
      return new Promise((resolve, reject) => {
        let url =
            window.Routes.getRoute("reset_password");
        window.axios.post(url, {email: email}).then(
            response => {
              resolve(response.data);
            },
            error => {
              let errors = error.response.data.errors;
              reject(errors);
            }
        );
      });
    },
  }
}
</script>

<style scoped lang="scss">
.forgot-password {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  height: 70vh;
  padding: map-get($spacings, "xxl") 0;

  .forgot-password-section {
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    padding: map-get($spacings, "xxl") 0;
    text-align: center;
    font-family: azo-sans-web, serif;

    h1 {
      font-weight: 600;
    }

    h4 {
      color: map-get($colors, "secondary");
      padding-bottom: map-get($spacings, "large");
    }

    .fp-email {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
    }

    .btn-recovery {
      button {
        width: 300px;
        height: 40px;
        margin-top: map-get($spacings, "big");
        margin-bottom: map-get($spacings, "small");
      }
    }

    a {
      color: map-get($colors, "secondary");
      text-transform: capitalize;
      font-weight: 600;
    }
  }
}
</style>
